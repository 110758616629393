import React from 'react';
import Layout from '@/layouts/main';
import { Mixpanel } from '@/utils';
import VitractUniversity from '@/views/vitract-university';

export default function VitractUniversityPage() {
  React.useEffect(() => Mixpanel.track(`View Vitract University Page`), []);
  return (
    <Layout
      title="Vitract University - Transforming mental health through the gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="vitract university"
    >
      <VitractUniversity />
    </Layout>
  );
}
